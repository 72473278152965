import request from '@/utils/request'

const water_billApi = {
  water_bill_list: '/water/bill/',
  water_bill_create: '/water/bill/',
  water_bill_update: '/water/bill/',
  water_bill_delete: '/water/bill/',
  water_bill_partial_update: '/water/bill/',
  water_bill_excel_list: '/water/bill/export/'
}

/**
 * 列表
 */
export function water_bill_list (parameter) {
  return request({
    url: water_billApi.water_bill_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function water_bill_create (parameter) {
  return request({
    url: water_billApi.water_bill_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function water_bill_update (parameter, water_bill_id) {
  return request({
    url: water_billApi.water_bill_update + water_bill_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function water_bill_delete (water_bill_id) {
  return request({
    url: water_billApi.water_bill_delete + water_bill_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 修改
 * @param
 * {
 *  "change_to": 0
 * }
 */
export function water_bill_partial_update (parameter, water_bill_id) {
  return request({
    url: water_billApi.water_bill_partial_update + water_bill_id + '/',
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 导出
 */
export function water_bill_excel_list (parameter) {
  return request({
    url: water_billApi.water_bill_excel_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/octet-stream'
    },
    responseType: 'blob',
    params: parameter
  })
}
